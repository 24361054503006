<template>
  <div>
    <vue-google-autocomplete
      v-model="currentAddress"
      @placechanged="getAddressData"
      @change="changeAddress"
      id="map"
      ref="toAddress"
      classname="map-control"
      placeholder="Адреса*"
      country="ua"
      :style="isAddressEmpty ? { color: '#ff5252', borderBottomColor: '#ff5252' } : { color: '' }"
    >
    </vue-google-autocomplete>
    <div v-if="isAddressEmpty" class="validation-error">Це поле обов"язкове</div>
    <div v-if="isBuildingNumberEmpty" class="validation-error">Введіть номер будинку</div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { isObjectEmpty } from '@/helpers/isObjectEmpty'

export default {
  name: 'AddressAutocomplete',

  components: { VueGoogleAutocomplete },

  mixins: [],

  props: {
    /*companyId: {
      type: Number,
      default: null,
    },
    servicePointId: {
      type: Number,
      default: null,
    },
    partnerId: {
      type: Number,
      default: null,
    },*/
    entityId: {
      type: Number,
      required: false,
      default: null,
    },
    addressId: {
      type: Number,
      default: null,
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    type: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: function () {
    return {
      currentAddress: '',
      editedItem: {},
      isAddressEmpty: false,
    }
  },

  computed: {
    ...mapState('addresses', ['address']),
    ...mapGetters('companies', ['getCreatedCompanyId']),
    ...mapGetters('servicePoints', ['getCreatedServicePointId']),
    ...mapGetters('partners', ['getCreatedPartnerId']),

    currentCompanyId() {
      return this.$route.params.id
    },
    isBuildingNumberEmpty() {
      return this.currentAddress && !this.editedItem.building_number
    },
  },

  async created() {
    if (this.addressId) await this.loadCurrentAddress()
    this.initialize()
  },

  beforeDestroy() {
    this.SET_ADDRESS({})
  },

  watch: {
    isDataSaved(val) {
      if (val) this.saveData()
    },
  },

  methods: {
    ...mapActions('addresses', [
      'createCompanyAddress',
      'createServicePointAddress',
      'createPartnerAddress',
      'loadAddress',
      'updateAddress',
    ]),
    ...mapMutations('addresses', ['SET_ADDRESS']),

    async loadCurrentAddress() {
      let addressPayload = {
        companyId: this.type === 'company' ? this.entityId : this.currentCompanyId,
        addressId: this.addressId,
        type: this.type,
      }
      switch (this.type) {
        case 'partner':
          addressPayload.partnerId = this.entityId
          break
        case 'servicePoint':
          addressPayload.servicePointId = this.entityId
          break
      }
      await this.loadAddress(addressPayload)
    },
    initialize() {
      this.editedItem = { ...this.address }
      if (this.address && !isObjectEmpty(this.address)) {
        this.currentAddress = `${this.editedItem.street}, ${this.editedItem.building_number}, ${this.editedItem.city}, ${this.editedItem.country}`
      } else {
        this.currentAddress = ''
      }
    },
    getAddressData(addressData) {
      this.editedItem.country = addressData.country
      this.editedItem.city = addressData.locality
      this.editedItem.street = addressData.route
      this.editedItem.building_number = addressData.street_number
      this.editedItem.latitude = addressData.latitude
      this.editedItem.longitude = addressData.longitude
      this.currentAddress = `${addressData.route}, ${addressData.street_number}, ${addressData.locality}, ${addressData.country}`
      this.handleButtonClicked()
    },
    async saveData() {
      if (this.address && !isObjectEmpty(this.address)) {
        const payload = {
          companyId: this.type === 'company' ? this.entityId : this.currentCompanyId,
          updatedAddress: this.editedItem,
          addressId: this.addressId,
          type: this.type,
          servicePointId: this.type === 'servicePoint' ? this.entityId : null,
          partnerId: this.type === 'partner' ? this.entityId : null,
        }
        delete payload.updatedAddress.id
        await this.updateAddress(payload)
      } else {
        const payload = {
          newAddress: this.editedItem,
        }
        switch (this.type) {
          case 'company':
            payload.id = this.getCreatedCompanyId
            await this.createCompanyAddress(payload)
            break
          case 'servicePoint':
            payload.id = this.currentCompanyId
            payload.servicePointId = this.getCreatedServicePointId
            await this.createServicePointAddress(payload)
            break
          case 'partner':
            payload.id = this.currentCompanyId
            payload.partnerId = this.getCreatedPartnerId
            await this.createPartnerAddress(payload)
            break
        }
      }
      this.$emit('updateItemsList')
    },
    changeAddress(val) {
      this.currentAddress = val
    },
    handleButtonClicked() {
      !this.currentAddress ? (this.isAddressEmpty = true) : (this.isAddressEmpty = false)
    },
  },
}
</script>

<style scoped lang="scss">
.map-control {
  width: 100%;
  outline: none;
  border-bottom: 1px solid #898b8c;
  height: 50px;
  font-size: 16px;
}
.validation-error {
  line-height: 12px;
  font-size: 12px;
  color: #ff5252;
  margin-top: 8px;
}
</style>
